import React from 'react';
import ReactDOM from 'react-dom/client';

import AppRouter from './common/router/router';

import reportWebVitals from './reportWebVitals';

import './styles/index.scss';

const rootElementID = document.getElementById('root');
if (rootElementID === null) throw new Error('Root container missing in index.html');

const root = ReactDOM.createRoot(rootElementID);

root.render(
	<React.StrictMode>
		<AppRouter />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
