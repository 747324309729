import { useEffect, lazy, Suspense } from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

const Home = lazy(() => import('../../pages/home/home'));
const Navigation = lazy(() => import('../components/navigation/navigation'));
const Footer = lazy(() => import('../components/footer/footer'));
const About = lazy(() => import('../../pages/about/about'));
const News = lazy(() => import('../../pages/news/news'));
const Games = lazy(() => import('../../pages/games/games'));
const Contacts = lazy(() => import('../../pages/contacts/contacts'));
const Careers = lazy(() => import('../../pages/careers/careers'));
const Privacy = lazy(() => import('../../pages/privacy/privacy'));
const RumiDefence = lazy(() => import('../../pages/rumi-defence/rumi-defence'));
const RumiDefenceRedirect = lazy(() => import('../../pages/rumi-defence/rumi-defence-redirect'));
const NotExists = lazy(() => import('../../pages/not-exists/not-exists'));
const JobDetails = lazy(() => import('../../pages/job-details/job-details'));
const Mutagen = lazy(() => import('../../pages/mutagen/mutagen'));
const MutagenRedirect = lazy(() => import('../../pages/mutagen/mutagen-redirect'));

const LoaderComponent = () => (
	<div className='loading-container'>
		<p className='loading-text' aria-label='Loading'>
			<span className='letter' aria-hidden='true'>
				L
			</span>
			<span className='letter' aria-hidden='true'>
				o
			</span>
			<span className='letter' aria-hidden='true'>
				a
			</span>
			<span className='letter' aria-hidden='true'>
				d
			</span>
			<span className='letter' aria-hidden='true'>
				i
			</span>
			<span className='letter' aria-hidden='true'>
				n
			</span>
			<span className='letter' aria-hidden='true'>
				g
			</span>
		</p>
	</div>
);

export default function AppRouter() {
	return (
		<Router basename='/'>
			<Suspense fallback={<LoaderComponent />}>
				<ScrollToTop />
				<Navigation />

				{/* Blank */}
				<div style={{ height: '100px' }} />

				<Routes>
					<Route path='/' element={<Home />} />

					<Route path='/about' element={<About />} />
					<Route path='/contacts' element={<Contacts />} />

					<Route path='/careers' element={<Careers />} />
					<Route path='/careers/:job' element={<JobDetails />} />

					<Route path='/news' element={<News />} />

					<Route path='/games' element={<Games />} />

					<Route path='/games/rumi-defence' element={<RumiDefence />} />
					<Route path='/games/rumi-defence-store' element={<RumiDefenceRedirect />} />

					<Route path='/games/mutagen' element={<Mutagen />} />
					<Route path='/games/mutagen-store' element={<MutagenRedirect />} />

					<Route path='/privacy' element={<Privacy />} />

					<Route path='*' element={<NotExists />} />
				</Routes>

				<Footer />
			</Suspense>
		</Router>
	);
}

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [pathname]);

	return null;
}
